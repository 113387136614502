import React from 'react'

import Gallery from '../../components/common/gallery';

const Ecuador = () => (
  <Gallery
    imageLinks={
      [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((number) => (
        `https://s3.amazonaws.com/valentina-site/artisans_images/ecuador/Ecuador${number}.jpg`
      ))
    }
    navigationColor='#c3c6cc'
    fromText='Artisans &amp; World'
    fromLink='/artisans_and_world'
  />
)

export default Ecuador